@font-face{
  font-family:"unit";
  src:url("fonts/unit-regular.woff2") format("woff2"),
    url("fonts/unit-regular.woff") format("woff");
  font-weight:400;
}

@font-face{
  font-family:"unit";
  src:url("fonts/unit-bold.woff2") format("woff2"),
    url("fonts/unit-bold.woff") format("woff");
  font-weight:700;
}
